import './scss/blocos_atividades.scss';

import Vue from 'vue';
import SvgIcon from '../../store-customization-v2/components/SvgIcon.vue';

const listaBlocos = document.getElementsByClassName('payt-block-atividades-wrapper');
for (let blocoAtividdades of listaBlocos) {
    createVueBlocoAtividadesApp(blocoAtividdades);
}

function createVueBlocoAtividadesApp(node) {
    if(!node) {
        return;
    }
    new Vue({
        el: node,
        components: { SvgIcon },
        data: function() {
            return {
                scrollLeftOffset: 0,
                listenerConfigured: false,
            };
        },
        computed:  {
            canScrollLeft() {
                return this.scrollLeftOffset > 20;
            },
            canScrollRight() {
                if(!this.listenerConfigured) {
                    return false;
                }
                const scrollSize = this.$refs.atividadesContainer.scrollWidth;
                const width = this.$refs.atividadesContainer.offsetWidth;
                return this.scrollLeftOffset < (scrollSize - width - 20);
            }
        },
        mounted() {
            this.configureListeners();
        },
        methods: {
            configureListeners() {
                if(!this.loading && !this.listenerConfigured && this.$refs.atividadesContainer) {
                    this.$refs.atividadesContainer.addEventListener('scroll', () => {
                        this.scrollLeftOffset = this.$refs.atividadesContainer.scrollLeft;
                    });
                    this.listenerConfigured = true;
                }
            },
            scrollRight() {
                this.$refs.atividadesContainer.scrollBy(this.$refs.atividadesContainer.clientWidth, 0);
            },
            scrollLeft() {
                this.$refs.atividadesContainer.scrollBy(-this.$refs.atividadesContainer.clientWidth, 0);
            },
        },
    });    
}
