<template>
<div
  ref="container"
  class="wrapper" />
</template>

<script>
import axios from 'axios';

export default {
    props: {
        width: {
            type: String,
            default: () => '100%',
            required: true
        },
        height: {
            type: String,
            default: () => '100%',
            required: true
        },
        color: {
            type: String,
            default: () => '#000000',
            required: true
        },
        iconUrl: {
            type: String,
            default: () => '',
            required: true
        },
    },
    data() {
        return { originalSvg: null, };
    },
    watch: {
        color() { this.configureSvg(); },
        height() { this.configureSvg(); },
        width() { this.configureSvg(); },
        iconUrl() { this.loadSvg(); },
    },
    mounted() {
        this.loadSvg();
    },
    methods: {
        configureSvg() {
            const svg = this.originalSvg.cloneNode(true);
            svg.style.setProperty('fill', this.$props.color, '');
            svg.setAttribute('height', this.$props.height);
            svg.setAttribute('width', this.$props.width);
            this.$refs.container.innerHTML = '';
            this.$refs.container.appendChild(svg);
        },
        async loadSvg() {
            const rawSvg = (await axios.get(this.$props.iconUrl)).data;
            const parser = new DOMParser();
            this.originalSvg = parser.parseFromString(rawSvg, 'image/svg+xml').firstChild;
            this.configureSvg();
        }
    }
};
</script>

<style scoped>
.wrapper {
    display: inline;
    overflow: hidden;
}
</style>
